import { Injectable } from '@angular/core';
import { SafeAny } from '../types/generic.types';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  extractApiError(error: SafeAny) {
    return error?.error?.message ?
      (Array.isArray(error.error.message) ? error.error.message[0] : error.error.message.toString()) :
      'An unexpected error occurred.';
  }
}
