import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzIconModule } from 'ng-zorro-antd/icon';

import { AppointmentService } from '@services/appointment.service';
import { Appointment } from 'src/app/models/appointment.model';
import { Subscription, filter, takeUntil } from 'rxjs';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';
import { CustomCurrencyPipe } from 'src/app/pipes/custom-currency.pipe';
import { AppointmentItem } from 'src/app/models/appointment-item.model';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ThemeService } from '@services/theme.service';
import { NavigationStart, Router } from '@angular/router';
import { CustomTimePipe } from '../../../pipes/custom-time.pipe';
import { ExpertsService } from '@services/experts.service';
import { DateTimeUtils } from 'src/app/lib/date-time-utils';
import { LocationService } from '@services/location.service';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { TimeFormatPipe } from '../../../pipes/time-format.pipe';
import { Location } from 'src/app/models/location.model';
import { environment } from '@env/environment';
import { AppointmentStatusInfoComponent } from '../appointment-status-info/appointment-status-info.component';
import { ErrorService } from '@services/error.service';

@Component({
  selector: 'app-cart-content',
  standalone: true,
  templateUrl: './cart-content.component.html',
  styleUrl: './cart-content.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CustomCurrencyPipe,
    NzIconModule,
    NzTypographyComponent,
    NzDividerModule,
    NzButtonModule,
    CustomTimePipe,
    DateFormatPipe,
    TimeFormatPipe,
    AppointmentStatusInfoComponent,
  ],
})
export class CartContentComponent extends SubscribedComponent implements OnInit {
  location?: Location;
  appointment: Appointment;
  continueBtnLabel: string;
  subscription: Subscription;
  showContinueButton: boolean = false;
  error: string;
  standaloneItems: AppointmentItem[];
  private routerSubscription: Subscription;

  constructor(
    private ref: ChangeDetectorRef,
    private locationService: LocationService,
    private appointmentService: AppointmentService,
    private expertsService: ExpertsService,
    private router: Router,
    private dateTimeUtils: DateTimeUtils,
    public themeService: ThemeService,
    private errorService: ErrorService,
  ) {
    super();
  }

  async ngOnInit() {
    this.subscription = this.locationService.locationChanged.pipe(takeUntil(this.destroyed$)).subscribe((location) => {
      this.location = location;
      this.ref.markForCheck();
    });
    this.location = this.locationService.getLocation();

    this.subscription = this.appointmentService.appointmentChanged
      .pipe(takeUntil(this.destroyed$))
      .subscribe((appointment) => {
        this.appointment = appointment;
        this.standaloneItems = this.appointmentService.getStandaloneItems();
        this.ref.markForCheck();
      });
    this.appointment = this.appointmentService.getCachedAppointment();
    this.standaloneItems = this.appointmentService.getStandaloneItems();

    const isTestAppointment = this.appointment?.test || this.location?.uuid === environment.defaultLocationUuid;
    const isCheckoutDisabled = this.appointmentService.isCheckoutDisabled();

    if (isTestAppointment) {
      this.themeService.setCartBtnInfoText("This is a test appointment. You won't be charged.");
      this.themeService.setCartBtnInfoType('danger');
      this.themeService.setCartBtnDisabled(false);
    } else if (isCheckoutDisabled) {
      this.themeService.setCartBtnInfoText('Online booking is currently unavailable.');
      this.themeService.setCartBtnInfoType('danger');
      this.themeService.setCartBtnDisabled(true);
    } else {
      this.themeService.setCartBtnInfoText('');
      this.themeService.setCartBtnInfoType('secondary');
      this.themeService.setCartBtnDisabled(false);
    }

    this.themeService
      .getCartBtnVisibility()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isVisible) => {
        this.showContinueButton = isVisible;
        this.ref.markForCheck();
      });

    this.themeService
      .getCartBtnLabel()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((label) => {
        this.continueBtnLabel = label;
        this.ref.markForCheck();
      });

    this.routerSubscription = this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationStart),
      )
      .subscribe(() => {
        this.showContinueButton = false;
        this.themeService.setFormSubmitAction(false);
      });
  }

  getExpertDetails(id: number) {
    return this.expertsService.getExpertDetails(id);
  }

  // TODO: for the moment not used from the template; but wait to get the OK for the design before remove it
  async onDeleteItem(item: AppointmentItem) {
    this.error = undefined;

    try {
      await this.appointmentService.deleteItem(item);
    } catch (e) {
      this.error = this.errorService.extractApiError(e);
    } finally {
      this.ref.detectChanges();
    }
  }

  // TODO: for the moment not used from the template; but wait to get the OK for the design before remove it
  getItemStartDate(startTime: string): Date {
    if (!startTime) return this.appointment.date;

    return this.dateTimeUtils.getEarliestDate(this.appointment.date, [startTime]);
  }

  getAddonsForItem(item: AppointmentItem): AppointmentItem[] {
    return this.appointmentService.getAddonsForItem(item.id);
  }

  getTotalPriceForItem(item: AppointmentItem): number {
    return this.appointmentService.getTotalPriceForItem(item);
  }

  getTotalDurationForItem(item: AppointmentItem): number {
    return this.appointmentService.getTotalDurationForItem(item);
  }

  onContinue() {
    this.themeService.setFormSubmitAction(true);
  }

  setContinueBtnLabel(label: string) {
    this.continueBtnLabel = label;
  }

  // TODO investigate how to better handle the appointment items in the appointment service, so the angular change detection works properly
  // once is done, the standaloneItems methid can be removed and we can call the appointmentService.getStandaloneItems() directly in the ngInit
  // standaloneItems(): AppointmentItem[] {
  //   if (!this.appointment.appointmentItems) return [];
  //   return this.appointmentService.getStandaloneItems();
  // }
}
