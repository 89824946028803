import { AppointmentItem } from './appointment-item.model';

export enum AppointmentStatus {
  Cart = 'Cart',
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Noshow = 'Noshow',
}

export enum AppointmentPaymentStatus {
  PayLater = 'PayLater', // Indicates payment is deferred but will be required later
  PartialPaymentRequired = 'PartialPaymentRequired', // A partial payment is required
  PartiallyPaid = 'PartiallyPaid', // Some portion of the payment has been made
  FullyPaid = 'FullyPaid', // The payment has been completed in full
  PaymentFailed = 'PaymentFailed', // The payment has failed
  PaymentNotRequired = 'PaymentNotRequired', // No payment is required
}

export enum PaymentTypeEnum {
  Deposit = 'deposit',
  Now = 'now',
  Venue = 'venue',
  Full = 'full',
}

export interface Appointment {
  id: number;
  locationId: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  date?: Date;
  servicesTotal?: number;
  deposit?: number;
  absorbFee?: boolean;
  bookingFee?: number;
  bookingFeeAmount?: number;
  totalPrice?: number;
  status: AppointmentStatus;
  paymentStatus: AppointmentPaymentStatus;
  appointmentItems: AppointmentItem[];
  specialRequests?: string;
  test: boolean;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
}
