import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocationService } from '@services/location.service';

@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(
    private locationService: LocationService,
    private currencyPipe: CurrencyPipe,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  transform(amount: number): string {
    const isInteger = Number.isInteger(amount);
    const digitsInfo = isInteger ? '1.0-0' : '1.2-2';
    const formattedValue = this.currencyPipe.transform(
      amount,
      this.locationService.getCurrency(),
      'symbol',
      digitsInfo,
      this.locale,
    );

    return formattedValue || '';
  }
}
