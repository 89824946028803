import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { Location } from '../models/location.model';
import { Expert } from '../models/expert.model';

@Injectable({
  providedIn: 'root',
})
export class ExpertsService {
  private location?: Location;
  private avatarColorList = [
    '#f56a00',
    '#7265e6',
    '#ffbf00',
    '#00a2ae',
    '#87d068',
    '#1890ff',
    '#fde3cf',
    '#ffbb96',
    '#b37feb',
    '#597ef7',
  ];

  constructor(private locationService: LocationService) {
    this.locationService.locationChanged.pipe().subscribe((location) => {
      this.location = location;
    });
  }

  getExpertDetails(id: number): Expert | undefined {
    return this.location.experts.find((expert) => expert.id === id);
  }

  avatarBgColor(expert: Expert): string {
    if (!expert.id) return '#BFBFBF';
    return this.avatarColorList[expert.id % this.avatarColorList.length];
  }

  avatarText(expert: Expert): string {
    return expert.firstName.charAt(0);
  }
}
