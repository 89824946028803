const host = 'https://api.timetailor.com/booking';

export const environment = {
  production: true,
  adminUrl: `https://app.timetailor.com`,
  apiUrl: `${host}/`,
  version: '6797018155c2c6dc20eb9de136b1789fb0996305',
  defaultLocationUuid: '700c5ebe-db11-4655-a5ec-ed7b8eb6e9a5',
  stripePublishableKey:
    'pk_live_51O2fkdCs1Y4LA3vDSoWJA9xPdzAMYruyaS1mA3xfqyMSORyhRuCLazQQOkLZmlNmf2R68V7QaQBA5mLsN4tuIu4r00UIKsHTdy',
};
